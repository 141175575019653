import { request, requestFile } from '../globalAxios';
import {serviceApi } from '../url';

  const login = async (data) => {
  console.log(data);
    const jsonData = {
      "email": data.email,
      "username": data.email,
      "password": data.password
    };

    const response =  await request('post', serviceApi.LOGIN_MEMBER,jsonData);
    return response;
  };
  const signup = async (data,ordId=null) => {
    const jsonData = {
      "name": data.name,
      "email": data.subemail,
      "whatsappNumber": data.whatsappnumber,
      "phoneNumber": data.phonenumber,
      "password": data.password,
      "roleId": 1,
      "organisationId": ordId
    };

    return await request('post', serviceApi.SIGNUP_MEMBER,jsonData);
  };

  const checkToken = async () => {
    const jsonData = {
      "token": localStorage.getItem("key")??"",
      "name": localStorage.getItem("username")??"", 
    };

    const response = await request('post', serviceApi.CHECK_TOKEN,jsonData);
    return response??false;
  };
export {login,signup,checkToken};