import React, { useEffect, useState } from 'react';
import Spinner from '../spinner';
import ModalPayementService from '../../domain/usecase/modal_payement_service';
import { Link, useNavigate } from 'react-router-dom';
import FormPayementBankCard from './form_payemrnt_bank_card';
import FormPayementMtnMM from './form_payement_mtn_mm';
import FormPayementOrangeMM from './form_payement_orange_mm';
import EventDetailService from '../../domain/usecase/cards_detail_services';
import ModalSubscription from '../modal_subscription_login/modal_subscription';
import MemberManagementService from '../../domain/usecase/member_management_service';
import InteractionVM from '../../view_model/interaction_vm';
import { InputAdornment, TextField } from '@mui/material';
import FormErrorFieldMessage from '../form_error_feild';
import { toast } from 'react-hot-toast';

const ModalPayement = () => {
  //const [show, setShow] = useState(false);

  //const handleClose = () => setShow(false);
  
  //const handleShow = () => setShow(true);

  const navigate = useNavigate();


  const { show, loading,toogleShow,step1,step2,isCheckPayment,isMtnPayement,isOrangePayement,toogleIsCheckPayment,
    toogleIsMtnPayement,toogleIsOrangePayement,switchStep,initModal
   } = ModalPayementService();
   const {isconnected,initAccount,showLogin,toogleShowLogin,isLogin,resetTokenValid,isTokenValide,isSubscription} = MemberManagementService();
   const { listPricing,addPurshareTicket,subPurshareTicket,taxe,saveCommenderTicker,initErrorCommenderData,errorCommenderData,commenderData,setCommenderData,formValidation} = EventDetailService();

   const handleRedirect = async () => {
    try {
      await formValidation(isconnected);
      const savedTickets = await saveCommenderTicker(isconnected);
      
      if (savedTickets) {
        toogleShow();
        navigate("/member_tickets", { replace: true, state: { refresh: true } });
      }
    } catch (error) {
      console.error("Error during redirect:", error);
      toast.error("Failed to save tickets");
    }
  };

    useEffect(()=>{
        initModal();
        initAccount(); 
        initErrorCommenderData();
    },[resetTokenValid]);
  
    return (
        <>
        {showLogin&&<ModalSubscription show={showLogin} toogleShow={()=>toogleShowLogin(false)} isLogin={isLogin} isSubscription={isSubscription}/>}
          <button className="button-round" onClick={toogleShow} >
            Place Order
          </button>
    
          <div className={`modal ${show ? 'show' : ''}`} style={{ display: show ? 'block' : 'none' }} tabIndex="-1" role="dialog">
            <div className="modal-dialog modal-dialog-centered modal-xl" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  {step1&&<h5 className="modal-title">Purshare your ticket</h5>}
                  {step2&&<h5 className="modal-title">Billing information</h5>}
                  <button type="button" className="close" aria-label="Close" onClick={toogleShow}>
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>  
                <div className="modal-body">
                    <main id="content" className="site-main">
                    {/*<!-- Inner Banner html start-->*/}
                    <div className="cart-section">
                    <div className="container">
                        <div className="cart-list-inner">
                            <form action="#">
                                <div className='row '>
                                    {step1&&<div className='col col-md-8'>
                                        <div className="table-responsive">
                                            <table className="table">
                                                <thead>
                                                <tr>
                                                    <th style={{color:"#fff",backgroundColor:"#263238"}}>Label</th>
                                                    <th style={{color:"#fff",backgroundColor:"#263238"}}>Price</th>
                                                    <th style={{color:"#fff",backgroundColor:"#263238"}}>Quantity</th>
                                                    <th style={{color:"#fff",backgroundColor:"#263238"}}>Sub Total</th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                    {listPricing.map((p,index)=><tr>
                                                    <td data-column="Product Name">{p.priceCategorie}</td>
                                                    <td data-column="Price">XAF {p.cost}</td>
                                                    <td data-column="Quantity" >
                                                        <div className="post-navigation-wrap">
                                                            <nav>
                                                            <ul className="pagination">
                                                                {/* Previous Button */}
                                                                <li>
                                                                <Link
                                                                    to="#"
                                                                    onClick={(event)=>{
                                                                        event.preventDefault();
                                                                        subPurshareTicket(index);
                                                                    }}
                                                                    className={'disabled'} 
                                                                >
                                                                    <i className="fas fa-minus"></i>
                                                                </Link>
                                                                </li>
                                                    
                                                                {/* Ellipsis for Overflow */}
                                                                
                                                                <li>
                                                                    <Link to="#" style={{fontSize:"15pt"}}>{p.purshareQuantity}</Link>
                                                                </li>
                                                                
                                                    
                                                                {/* Next Button */}
                                                                <li>
                                                                <Link
                                                                    to="#"
                                                                    onClick={(event) => {
                                                                        addPurshareTicket(index);
                                                                        event.preventDefault();                                                                    
                                                                    }}
                                                                    className={'disabled'}
                                                                >
                                                                    <i className="fas fa-plus"></i>
                                                                </Link>
                                                                </li>
                                                            </ul>
                                                            </nav>
                                                        </div>
                                                        
                                                    </td>
                                                    <td data-column="Sub Total">XAF {(p.cost*p.purshareQuantity)}</td>
                                                </tr>)}
                                                
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>}
                                    {step2&&<div className='col col-md-8'>
                                        
                                        <form>
                                            {(!isTokenValide||!isconnected)&&<div className='row'>
                                                <span className="byline">
                                                    <h4 >Enter your infomation to receive ticket </h4>
                                                </span>
                                                <div className='row'>
                                                    <div className="col">
                                                        <div className="form-group">
                                                            <Link to="#" onClick={()=>{
                                                                toogleShowLogin(true);
                                                                toogleShow();
                                                                console.log("-- action --");
                                                            }}>Connectez vous</Link> pour une experiace plus rapide.
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                    {errorCommenderData.firstName.activate&&<FormErrorFieldMessage message={errorCommenderData.firstName.message}  />}
                                                    <TextField id="outlined-uncontrolled" value={commenderData.firstname} inputProps={{ maxLength: 40 }} onChange={(event) => {
                                                        setCommenderData("firstName",event.target.value);                                            
                                                    }}
                                                    label="First Name *" placeholder="Enter your first name" sx={{ minWidth: "100%" }}
                                                        InputProps={{startAdornment: <InputAdornment position="start"><i className="fa fa-cogs"/></InputAdornment>,}}
                                                    />
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                    {errorCommenderData.lastName.activate&&<FormErrorFieldMessage message={errorCommenderData.lastName.message}  />}
                                                    <TextField id="outlined-uncontrolled" value={commenderData.firstname} inputProps={{ maxLength: 40 }} onChange={(event) => {
                                                        setCommenderData("lastName",event.target.value);                                            
                                                    }}
                                                    label="Last Name *" placeholder="Enter your last name" sx={{ minWidth: "100%" }}
                                                        InputProps={{startAdornment: <InputAdornment position="start"><i className="fa fa-cogs"/></InputAdornment>,}}
                                                    />
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                    {errorCommenderData.username.activate&&<FormErrorFieldMessage message={errorCommenderData.username.message}  />}
                                                    <TextField id="outlined-uncontrolled" value={commenderData.firstname} inputProps={{ maxLength: 40 }} onChange={(event) => {
                                                        setCommenderData("username",event.target.value);                                            
                                                    }}
                                                    label="Email *" placeholder="Enter Email to receive ticket" sx={{ minWidth: "100%" }}
                                                        InputProps={{startAdornment: <InputAdornment position="start"><i className="fa fa-cogs"/></InputAdornment>,}}
                                                    />
                                                    
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                <div className="form-group">
                                                    {errorCommenderData.username.activate&&<FormErrorFieldMessage message={errorCommenderData.username.message}  />}
                                                    <TextField id="outlined-uncontrolled" value={commenderData.firstname} inputProps={{ maxLength: 40 }} onChange={(event) => {
                                                        setCommenderData("username",event.target.value);                                            
                                                    }}
                                                    label="Confirm Email *" placeholder="Enter Email to receive ticket" sx={{ minWidth: "100%" }}
                                                        InputProps={{startAdornment: <InputAdornment position="start"><i className="fa fa-cogs"/></InputAdornment>,}}
                                                    />
                                                    </div>
                                                </div>
                                            </div>}
                                           {/*<div className="col-sm-6">
                                                    <div className="form-group">
                                                    <label>First Name *</label>
                                                    <input type="text" name="name"/>
                                                    </div>
                                                </div>
                                                <div className="col-sm-6">
                                                    <div className="form-group">
                                                        <label>last Name *</label>
                                                        <input type="text" name="name"/>
                                                    </div>
                                                </div>
                                                <div className="col-sm-6">
                                                    <div className="form-group">
                                                    <label>Email *</label>
                                                    <input type="text" name="name"/>
                                                    </div>
                                                </div>
                                                <div className="col-sm-6">
                                                    <div className="form-group">
                                                        <label>Confirm Email *</label>
                                                        <input type="text" name="name"/>
                                                    </div>
                                                </div>
                                                #*/}
                                                
                                            <div className="payment-wrap">
                                                <span className="byline">
                                                    <h4 >Select your payment method </h4>
                                                </span>
                                                <div className="form-group">
                                                    <div className="custom-radio-field" >
                                                        
                                                        <div className='row' >
                                                            <label className='payement_option' onClick={toogleIsCheckPayment}>
                                                                <input type="radio" name="s" value="2"  />
                                                                <span className="radio-field"></span>
                                                                Check payment  <img src="/assets/images/momo/paypal.png" style={{width:"40px"}}/>
                                                            </label>    
                                                                                                               
                                                        </div>
                                                    </div>
                                                    {isCheckPayment && <FormPayementBankCard /> }    
                                                </div>
                                                <div className="form-group">
                                                    <div className="custom-radio-field" onClick={toogleIsMtnPayement} >
                                                        <label>
                                                            <input type="radio" name="s" value="3" />
                                                            <span className="radio-field"></span>
                                                            MTN Mobile Money <img src="/assets/images/momo/mtn_momo.jpeg" style={{width:"40px"}} alt="" />
                                                        </label>
                                                         
                                                    </div>
                                                    {isMtnPayement && <FormPayementMtnMM />}
                                                </div>
                                                <div className="form-group">
                                                    <div className="custom-radio-field"  onClick={toogleIsOrangePayement}>
                                                        <label>
                                                            <input type="radio" name="s" value="4" />
                                                            <span className="radio-field"></span>
                                                            Orange Mobile Money  <img src="/assets/images/momo/orange_momo.png" style={{width:"40px"}} alt="" />                                           
                                                        </label>                                                    
                                                    </div>
                                                    {isOrangePayement && <FormPayementOrangeMM />}
                                                </div>
                                            </div>
                                            
                                        </form>
                                    </div>}
                                    <div className='col col-md-4'>
                                        <div className="updateArea" style={{backgroundColor:"#263238"}}>
                                            {/*<div className="input-group-wrap">
                                                <input type="text" className="form-control" placeholder="I have a discount coupon"/>
                                                <a href="#" className="button-round mt-2">apply coupon</a>
                                            </div>*/}
                                        
                                        </div>
                                        <div className="totalAmountArea">
                                            <ul className="list-unstyled">
                                                <li><strong>Sub Total</strong> <span>XAF {listPricing.reduce((sum, item) => {
                                                        return sum + (item.purshareQuantity * item.cost);
                                                    }, 0.0)}</span></li>
                                                <li><strong>Tax</strong> <span>{taxe}</span></li>
                                                <li><strong>Grand Total</strong> <span className="grandTotal">XAF {listPricing.reduce((sum, item) => {
                                                        return sum + (item.purshareQuantity * item.cost) + ((item.purshareQuantity * item.cost) * taxe/100);
                                                    }, 0.0)}</span></li>
                                            </ul>
                                        </div>
                                        
                                    </div>
                                </div>
                                <div className='row'>
                                {step2&&<div className="text-center col col-md-4">
                                            <div className="button-round " style={{backgroundColor:"#4f5b62",color:"#fff"}} onClick={()=>switchStep(true,false)}> Cancel</div>
                                            
                                    </div>}
                                    {step1&&listPricing.reduce((sum, item) => {
                                                        return sum + (item.purshareQuantity * item.cost);
                                                    }, 0.0)>0&&<div className="text-center">
                                            <div className="button-round" onClick={()=>switchStep(false,true)
                                            }> Purshare</div>
                                            
                                    </div>}
                                    {step2&&<div className="text-center col col-md-4">
                                            {loading?<div className="button-round"> <Spinner/> Loading...</div>:<div className="button-round" onClick={handleRedirect}> Buy</div>} {/**toogleLoading */}
                                            
                                    </div>}
                                </div>
                            </form>
                        </div>
                    </div>
                    </div>
                </main>
                
                </div>
                
              </div>
            </div>
          </div>
          {show && <div className="modal-backdrop fade show"></div>}
        </>
      );
  
};

export default ModalPayement;
