import React, { useState } from 'react';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Chip,
} from '@mui/material';

const EventsTable = () => {
  const [searchTerm, setSearchTerm] = useState('');
  
  const events = [
    {
      eventName: 'Summer Festival',
      dateCreate: new Date().toLocaleDateString(),
      ticketSales: 150,
      ticketValidate: 120,
      status: 'active',
    },
    // Add more events...
  ];

  const filteredEvents = events.filter(event => 
    event.eventName.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <Paper sx={{ p: 2 }}>
      <TextField
        label="Search Events"
        variant="outlined"
        fullWidth
        margin="normal"
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
      />

      <TableContainer>
        <Table sx={{ minWidth: { xs: 300, sm: 650 } }}>
          <TableHead>
            <TableRow sx={{ backgroundColor: '#263238', color: 'white' }}>
              <TableCell sx={{ color: 'white' }}>Event Name</TableCell>
              <TableCell sx={{ display: { xs: 'none', sm: 'table-cell' }, color: 'white' }}>Date Created</TableCell>
              <TableCell sx={{ display: { xs: 'none', md: 'table-cell' }, color: 'white' }}>Ticket Sales</TableCell>
              <TableCell sx={{ display: { xs: 'none', md: 'table-cell' }, color: 'white' }}>Validated Tickets</TableCell>
              <TableCell sx={{ color: 'white' }}>Status</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredEvents.map((event) => (
              <TableRow 
                key={event.eventName}
                sx={{ backgroundColor: 'white' }}
              >
                <TableCell>{event.eventName}</TableCell>
                <TableCell sx={{ display: { xs: 'none', sm: 'table-cell' } }}>{event.dateCreate}</TableCell>
                <TableCell sx={{ display: { xs: 'none', md: 'table-cell' } }}>{event.ticketSales}</TableCell>
                <TableCell sx={{ display: { xs: 'none', md: 'table-cell' } }}>{event.ticketValidate}</TableCell>
                <TableCell>
                  <Chip
                    label={event.status}
                    color={
                      event.status === 'active' ? 'success' :
                      event.status === 'completed' ? 'primary' : 'error'
                    }
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
};

export default EventsTable; 