import React, { useState } from 'react';
import { Box, Grid } from '@mui/material';
import AdminSidebar from './components/AdminSidebar';
import AdminHeader from './components/AdminHeader';
import AdminFooter from './components/AdminFooter';
import StatsCards from './components/StatsCards';
import EventsTable from './components/EventsTable';
import MonthlyChart from './components/MonthlyChart';

const drawerWidth = 240;
const closedDrawerWidth = 65;

const AdminDashboard = () => {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const isMobile = window.innerWidth <= 600;

  return (
    <Box sx={{ display: 'flex' }}>
      <AdminSidebar open={sidebarOpen} onToggle={() => setSidebarOpen(!sidebarOpen)} />
      
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          width: {
            xs: '100%',
            sm: `calc(100% - ${sidebarOpen ? drawerWidth : closedDrawerWidth}px)`
          },
          marginLeft: 0,
          transition: theme =>
            theme.transitions.create(['width'], {
              easing: theme.transitions.easing.sharp,
              duration: theme.transitions.duration.leavingScreen,
            }),
          display: 'flex',
          flexDirection: 'column',
          minHeight: '100vh',
        }}
      >
        <AdminHeader onMenuClick={() => setSidebarOpen(!sidebarOpen)} />
        
        <Box sx={{ p: { xs: 1, sm: 3 }, flexGrow: 1 }}>
          <Grid container spacing={{ xs: 1, sm: 3 }}>
            {/* Stats Cards */}
            <Grid item xs={12}>
              <StatsCards />
            </Grid>

            {/* Monthly Chart */}
            <Grid item xs={12}>
              <MonthlyChart />
            </Grid>

            {/* Events Table */}
            <Grid item xs={12}>
              <EventsTable />
            </Grid>
          </Grid>
        </Box>

        <AdminFooter />
      </Box>
    </Box>
  );
};

export default AdminDashboard; 