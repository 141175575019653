import React from 'react';
import { Box, Typography } from '@mui/material';
import CopyrightIcon from '@mui/icons-material/Copyright';
import EmailIcon from '@mui/icons-material/Email';
import PhoneIcon from '@mui/icons-material/Phone';
import ConfirmationNumberIcon from '@mui/icons-material/ConfirmationNumber';

const AdminFooter = () => {
  return (
    <Box
      component="footer"
      sx={{
        p: 2,
        mt: 'auto',
        backgroundColor: '#263238',
        display: 'flex',
        flexDirection: { xs: 'column', sm: 'row' },
        alignItems: 'center',
        justifyContent: 'space-between',
        color: 'white', // White text
        gap: { xs: 2, sm: 0 },
      }}
    >
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
        <CopyrightIcon fontSize="small" sx={{ color: '#D1711F' }} />
        <Typography variant="body2">
          Copyright D.Service 2025
        </Typography>
      </Box>

      <Box sx={{ display: 'flex', gap: 2, flexDirection: { xs: 'column', sm: 'row' } }}>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          <EmailIcon fontSize="small" sx={{ color: '#D1711F' }} />
          <Typography variant="body2" sx={{ textAlign: 'center' }}>
            dservice237@gmail.com
          </Typography>
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          <PhoneIcon fontSize="small" sx={{ color: '#D1711F' }} />
          <Typography variant="body2" sx={{ textAlign: 'center' }}>
            675888025/696871880
          </Typography>
        </Box>
      </Box>

      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
        <ConfirmationNumberIcon fontSize="small" sx={{ color: '#D1711F' }} />
        <Typography variant="body2">
          Best Digital ticket manager
        </Typography>
      </Box>
    </Box>
  );
};

export default AdminFooter; 