import React from 'react';
import { AppBar, Toolbar, IconButton, Typography, Button } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import { useNavigate } from 'react-router-dom';

const AdminHeader = ({ onMenuClick }) => {
  const navigate = useNavigate();

  const handleClose = () => {
    navigate('/event_tickets');
  };

  return (
    <AppBar
      position="sticky"
      sx={{
        backgroundColor: '#263238',
        boxShadow: 1,
      }}
    >
      <Toolbar>
        <IconButton
          edge="start"
          color="inherit"
          aria-label="menu"
          onClick={onMenuClick}
        >
          <MenuIcon />
        </IconButton>
        <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
          Admin Dashboard
        </Typography>
        <Button
          color="inherit"
          onClick={handleClose}
          startIcon={<CloseIcon sx={{ color: '#D1711F' }} />}
          sx={{
            '&:hover': {
              backgroundColor: 'rgba(255, 255, 255, 0.1)',
            },
            color: '#D1711F',
          }}
        >
          Close
        </Button>
      </Toolbar>
    </AppBar>
  );
};

export default AdminHeader; 