import React from 'react';
import { Grid, Paper, Typography } from '@mui/material';

const StatsCards = () => {
  const stats = [
    { title: 'Total Events', value: '120' },
    { title: 'Total Sales', value: '1,234' },
    { title: 'Active Events', value: '45' },
    { title: 'Validated Tickets', value: '890' },
  ];

  return (
    <Grid container spacing={3}>
      {stats.map((stat) => (
        <Grid item xs={12} sm={6} md={3} key={stat.title}>
          <Paper
            sx={{
              p: 2,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Typography variant="h6" component="h3">
              {stat.title}
            </Typography>
            <Typography variant="h4" component="div" sx={{ color: '#D1711F' }}>
              {stat.value}
            </Typography>
          </Paper>
        </Grid>
      ))}
    </Grid>
  );
};

export default StatsCards; 