import {serviceApi } from '../url';
import RequestResponse from '../../domain/model/request_responce';
import EventModel from '../../domain/model/event_model';
import { request, requestFile } from '../globalAxios';
import dayjs from 'dayjs';

const getEvents = async (page,pageSize) => {
  const response =  await request('get', serviceApi.GET_ALL_EVENT+"?page="+(page>0?page-1:page)+"&size="+pageSize,);
  return new RequestResponse(response.data.map((event)=>new EventModel(event)),response.sucess,response.pageSize,response.page,response.totalElement,response.totalPages,response.exception);
};

const getEventsByCategory = async (keyFilter,page,pageSize) => {
  const response =  await request('get', serviceApi.GET_ALL_EVENT_CATEGORY+"?name="+keyFilter+"&page="+(page>0?page-1:page)+"&size="+pageSize);
  return new RequestResponse(response.data.map((event)=>new EventModel(event)),response.sucess,response.pageSize,response.page,response.totalElement,response.totalPages,response.exception);
};

const getEventsByPeriod = async (periode,page,pageSize) => {
  console.log("-- data --",{
    startDate:dayjs(periode.startDate).format("YYYY-MM-DD"),endDate:dayjs(periode.endDate).format("YYYY-MM-DD"),page:page,size:pageSize
  });
  
  const response =  await request('get', serviceApi.GET_ALL_EVENT_DATE+"?startDate="+dayjs(periode.startDate).format("YYYY-MM-DD")+"&endDate="+
  dayjs(periode.endDate).format("YYYY-MM-DD")+"&page="+(page>0?page-1:page)+"&size="+pageSize,);
  return new RequestResponse(response.data.map((event)=>new EventModel(event)),response.sucess,response.pageSize,response.page,response.totalElement,response.totalPages,response.exception);
};

const getEventsDetail = async (id) => {
  const response =  await request('get', serviceApi.GET_DETAILS+"?id="+id);
  return new RequestResponse(new EventModel(response.data),response.sucess,response.pageSize,response.page,response.totalElement,response.totalPages,response.exception);
};

const saveEvents = async (eventDTO,key) => {
  const formData = new FormData();
  // Append the non-file fields
  formData.append('title', eventDTO.title);
  formData.append('organizer', eventDTO.organizer);
  formData.append('latitude', eventDTO.latitude);
  formData.append('longitude', eventDTO.longitude);
  formData.append('area', eventDTO.area);
  formData.append('detailLocation', eventDTO.autherLocation);
  formData.append('tag', eventDTO.tag);
  formData.append('summary', eventDTO.summary);
  formData.append('description', eventDTO.description);
  formData.append('offline', eventDTO.offline);
  formData.append('country', eventDTO.country);

  formData.append('startHour', eventDTO.startHour);
  formData.append('endHour', eventDTO.endHour);
  formData.append('town', eventDTO.town);
  formData.append('quantity', eventDTO.quantity);
  formData.append('periodique', eventDTO.periodique);
  formData.append('singleEvent', eventDTO.singleEvent);
  formData.append('recurcingEvent', eventDTO.recurcingEvent);
  formData.append('displayStartTime', eventDTO.displayStartTime);
  formData.append('displayEndTime', eventDTO.displayEndTime);
  formData.append('activate', true);
  formData.append('firstTime', eventDTO.firstTime); 
  formData.append('startDate', eventDTO.startDate);
  formData.append('dateEnd', eventDTO.dateEnd);
  formData.append('eventCategoryId', eventDTO.eventCategoryid);
  formData.append('typeId', eventDTO.typeid);
  formData.append('memberMail', eventDTO.memberMail);
  formData.append('firstTime', eventDTO.firstTime);

  // Append the file fields
  console.log("eventDTO.descriptionImage1 == >",eventDTO.descriptionImage1);
  console.log("eventDTO.descriptionImage2 == >",eventDTO.descriptionImage2);
  console.log("eventDTO.mainImage == >",eventDTO.mainImage);
  formData.append('descriptionImage1', eventDTO.descriptionImage1);
  formData.append('descriptionImage2', eventDTO.descriptionImage2);
  formData.append('mainPicture', eventDTO.mainImage); 

  const response =  await requestFile('POST', serviceApi.SAVE_EVENT,formData,key); 
  console.log("sava data -- ",response);
  if(response.error){
    return new RequestResponse(EventModel(response.error.data),response.error.sucess,response.error.pageSize,response.error.page,response.error.totalElement,response.error.totalPages,response.error.exception);
  }
  return response;
};

export {getEvents,saveEvents,getEventsByCategory,getEventsByPeriod, getEventsDetail};