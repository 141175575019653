import React from "react";
import { Card, CardContent, CardMedia, Typography, Box } from "@mui/material";
import QRCode from "react-qr-code";
 // Assuming you will create a corresponding SCSS file

const VerticalTicket = ({ event, ticket, cid }) => {
  return (
    <div className="vertical-ticket">
      <div className="ticket-header">
        <h2>{event.name}</h2>
        <p>{ticket.type}</p>
      </div>
      <div className="ticket-body">
        <p>Price: ${ticket.price}</p>
        <p>Event Date: {event.date}</p>
        <p>Location: {event.location}</p>
      </div>
      <div className="ticket-footer">
        <button className="buy-button">Buy Now</button>
      </div>
    </div>
  );
};

export default VerticalTicket;
