import React, { Fragment } from "react";
import { useTranslation } from "react-i18next";
import HorizontalTicket from "./tickets/horizontal_ticket";
import EventDetailService from "../domain/usecase/cards_detail_services";

const MyTickets = ()=>{

    //const {t} = useTranslation(['about_us','common']);
    const { listTicketPay} = EventDetailService();

    return (
        <Fragment>
           <main id="content" className="site-main">
                {/*<!-- Inner Banner html start-->{t('COMMON_SEARCH',{ns:"common"})}*/}
                <section className="inner-banner-wrap">
                    <div className="inner-baner-container">
                        <div className="container">
                            <div className="inner-banner-content">
                                <h2 className="inner-title">My Tickets</h2><br/>
                            </div>
                        </div>
                        <div className="overlay-image"></div>
                        
                    </div>
                </section>
                {/*<!-- About start -->*/}
                <div className="about-page-section">
                    <div className="about-inner-page">
                        <div className="container">
                            {listTicketPay.map( Element => <HorizontalTicket event={Element.eventDTO} ticket={Element.pricingDTO} cid={Element.cid} />)}
                        </div>
                    </div>
                </div>
           </main>
        </Fragment>
    );
}

export default MyTickets;

