import React from "react";
import { Link } from "react-router-dom";
import { ipfsUrl } from "../../data/url";

const HorizontalTicket = ({event,ticket,cid}) => {
  return (
    <div className="ticket-container mb-3">
      <div className="ticket">
        <div className="ticket-left">
          <div className="ticket-edge-left"></div>
          <div className="ticket-content">
            <div className="ticket-title">
              <h3>{event.title}</h3>
              <span className="ticket-date">{event.createdDate} - 12h30</span>
            </div>
            
            <div className="ticket-details">
              <div className="ticket-detail-item">
                <span className="detail-label">Place</span>
                <span className="detail-value">{event.area}</span>
              </div>
              
              <div className="ticket-detail-item">
                <span className="detail-label">Category</span>
                <span className="detail-value">{ticket.priceCategorie}</span>
              </div>
              
              <div className="ticket-detail-item">
                <span className="detail-label">Collation</span>
                <span className="detail-value">{ticket.collation}</span>
              </div>
            </div>
          </div>
        </div>
        
        <div className="ticket-right">
          <div className="ticket-edge-right"></div>
          <div className="download-section">
            <button 
              className="download-button"
              onClick={() => window.open(ipfsUrl.IPFS_GET_IMAGE+cid, '_blank')}
            >
              <i className="fas fa-download"></i>
              Download
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

const styles = `
.ticket-container {
  padding: 20px;
}

.ticket {
  display: flex;
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  position: relative;
  overflow: hidden;
  max-width: 800px;
  margin: 0 auto;
}

.ticket::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 5px;
  background: linear-gradient(45deg, #D1711F, #f39c12);
}

.ticket-left {
  flex: 3;
  padding: 20px;
  position: relative;
  border-right: 2px dashed #ddd;
}

.ticket-right {
  flex: 1;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f8f9fa;
}

.ticket-edge-left,
.ticket-edge-right {
  position: absolute;
  width: 20px;
  height: 20px;
  background: #fff;
  border-radius: 50%;
}

.ticket-edge-left {
  left: -10px;
  top: 50%;
  transform: translateY(-50%);
  box-shadow: inset -1px 0 3px rgba(0,0,0,0.1);
}

.ticket-edge-right {
  right: -10px;
  top: 50%;
  transform: translateY(-50%);
  box-shadow: inset 1px 0 3px rgba(0,0,0,0.1);
}

.ticket-title {
  margin-bottom: 20px;
}

.ticket-title h3 {
  color: #2c3e50;
  margin: 0;
  font-size: 1.5em;
}

.ticket-date {
  color: #7f8c8d;
  font-size: 0.9em;
}

.ticket-details {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 15px;
}

.ticket-detail-item {
  display: flex;
  flex-direction: column;
}

.detail-label {
  color: #7f8c8d;
  font-size: 0.8em;
  text-transform: uppercase;
  margin-bottom: 5px;
}

.detail-value {
  color: #2c3e50;
  font-weight: 500;
}

.download-button {
  background: #D1711F;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  transition: background 0.3s ease;
  display: flex;
  align-items: center;
  gap: 8px;
}

.download-button:hover {
  background: #c26118;
}

.download-button i {
  font-size: 1.2em;
}
`;

// Inject styles
const styleSheet = document.createElement("style");
styleSheet.innerText = styles;
document.head.appendChild(styleSheet);

export default HorizontalTicket;
