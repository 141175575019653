import {create} from 'zustand';
import { getEventsDetail } from '../../data/api/event_api';
import { getEventPricings } from '../../data/api/event_pricing_api';
import toast from 'react-hot-toast';
import { createCommenderTicketService } from '../service/eventService';
import { persist } from 'zustand/middleware';

const getInitialTickets = () => {
  try {
    const storedData = localStorage.getItem("ticket-store");
    if (storedData) {
      const parsedData = JSON.parse(storedData);
      return parsedData.state.listTicketPay || [];
    }
  } catch (error) {
    console.error("Error loading initial tickets:", error);
  }
  return [];
};

const EventDetailService = create(persist((set,get) => ({
  showMap: true,
  event: {},
  listPricing: [],
  listTicketPay: getInitialTickets(),
  imageCID: "",
  imageCID1: "",
  imageCID2: "",
  commenderData: {
    "firstName":"",
    "lastName":"",
    "keepIntoch": false,
    "closeEvent": false,
    "username": ""
  },
  isErrorFound:false,
  errorCommenderData: {
    firstName:{
      activate:false,
      message: ""
    },
    lastName:{
      activate:false,
      message: ""
    },
    username:{
      activate:false,
      message: ""
    }
  },
  initErrorCommenderData:()=>{
    set({
      isErrorFound:false,
      errorCommenderData:{
      firstName:{
        activate:false,
        message: ""
      },
      lastName:{
        activate:false,
        message: ""
      },
      username:{
        activate:false,
        message: ""
      }
    }});
  },
  taxe: 1.3,
  totalNumberPlace: 0,
  toggleShowMap: () => {
    set({showMap: !get().showMap });
  },
  setCommenderData:(key,value)=>{
    const newCommenderData = get().commenderData;
    switch(key){
      case "firstName": newCommenderData.firstName= value;  break;
      case "lastName": newCommenderData.lastName= value;  break;
      case "username": newCommenderData.username= value;  break;
      default: console.log("************** unkonw key *****************");
    }

    set({commenderData: newCommenderData});
    console.log(get().commenderData);
  },
  formValidation:(isconnected)=>{
    const commenderData = get().commenderData;
    const newErrorCommenderData = get().errorCommenderData;

    if(!isconnected){
      if(commenderData.firstName===""){
        newErrorCommenderData.firstName.activate = true;
        newErrorCommenderData.firstName.message = "Commender firstName require *";
        set({isErrorFound:true});
      }
      if(commenderData.lastName===""){
        newErrorCommenderData.lastName.activate = true;
        newErrorCommenderData.lastName.message = "Commender lastName require *";
        set({isErrorFound:true});
      }
      if(commenderData.username===""){
        newErrorCommenderData.username.activate = true;
        newErrorCommenderData.username.message = "Commender username require *";
        set({isErrorFound:true});
      }
    }
      
  },
  getTotalNumberPlace:()=>{
    var sum = 0;
    get().listPricing.forEach(element => {
      sum = sum + element.nbrTicket;
    });
    set({totalNumberPlace: sum});
  },
  addPurshareTicket:(index)=>{
    var listPricingUpdate = get().listPricing;
    listPricingUpdate.forEach((p,i)=>{
      if(i===index){
        listPricingUpdate[i].purshareQuantity += 1;
        console.log("listPricingUpdate[i].purshareQuantity == "+listPricingUpdate[i].purshareQuantity);
      }
    });

    set({listPricing:listPricingUpdate});
  },
  subPurshareTicket:(index)=>{
    var listPricingUpdate = get().listPricing;
    listPricingUpdate.forEach((p,i)=>{
      if(i===index&&p.purshareQuantity>0){
        listPricingUpdate[i].purshareQuantity -= 1;
      }
    });

    set({listPricing:listPricingUpdate});
  },
  fetcheventdetails: async (id)=>{
    const eventDetails = await getEventsDetail(id);
    if(eventDetails.success){
      
      const parts = eventDetails.data.mainImage.split("/");
      const parts1 = eventDetails.data.descriptionImage1.split("/");
      const parts2 = eventDetails.data.descriptionImage2.split("/");
      set({
        event:eventDetails.data,
        imageCID: parts[parts.length - 1],
        imageCID1: parts1[parts.length - 1],
        imageCID2: parts2[parts.length - 1],
      });
      get().getEventsDetailPricing(id);
      
      
    }
  },
  getEventsDetailPricing: async (id)=>{
    console.log("-- event pricings --");
    const response =await getEventPricings(id);

    if(response.success){
      set({listPricing:response.data});
      get().getTotalNumberPlace();
      console.log(response);
    }else{
      toast.alert(response.message);
    }
  },
  saveCommenderTicker: async (isconnected) => {
    try {
      const requestObject = isconnected ? {
        "firstName": localStorage.getItem("username"),
        "lastName": localStorage.getItem("username"),
        "linkTicket": "",
        "keepIntoch": false,
        "closeEvent": false,
        "connected": isconnected,
        "username": localStorage.getItem("username"),
      } : {
        "firstName": get().commenderData.firstName,
        "lastName": get().commenderData.lastName,
        "linkTicket": "",
        "keepIntoch": false,
        "closeEvent": false,
        "connected": isconnected,
        "username": get().commenderData.username,
      };

      // Call the service to create the ticket
      const commenderTicket = await createCommenderTicketService(requestObject, get().listPricing);
      console.log("Nouveaux tickets créés:", commenderTicket);

      // Check if commenderTicket is empty
      if (!commenderTicket || (Array.isArray(commenderTicket) && commenderTicket.length === 0)) {
        console.error("Aucun ticket créé, commenderTicket est vide.");
        return; // Early return if no tickets were created
      }

      // Update the state with the new tickets
      const updatedTickets = [
        ...get().listTicketPay,
        ...(Array.isArray(commenderTicket) ? commenderTicket : [commenderTicket])
      ];
      console.log("Liste mise à jour des tickets:", updatedTickets);

      // Update Zustand state
      set({ listTicketPay: updatedTickets });

      // Update localStorage
      const persistedData = {
        state: {
          listTicketPay: updatedTickets
        },
        version: 0
      };
      localStorage.setItem("ticket-store", JSON.stringify(persistedData));
      console.log("Cache mis à jour:", localStorage.getItem("ticket-store"));

      return commenderTicket;
    } catch (error) {
      console.error("Error saving ticket:", error);
      throw error;
    }
  },
  reloadTickets: () => {
    try {
      const storedData = localStorage.getItem("ticket-store");
      console.log("Données stockées brutes:", storedData);
      
      if (storedData) {
        const parsedData = JSON.parse(storedData);
        console.log("Données parsées:", parsedData);
        
        const tickets = parsedData.state.listTicketPay || [];
        console.log("Tickets chargés:", tickets);
        
        set({ listTicketPay: tickets });
      } else {
        console.log("Aucune donnée trouvée dans le cache");
      }
    } catch (error) {
      console.error("Error reloading tickets:", error);
      set({ listTicketPay: [] });
    }
  }
}),
{
  name: "ticket-store",
  partialize: (state) => ({
    listTicketPay: state.listTicketPay
  }),
  version: 0,
  getStorage: () => localStorage // Spécifier explicitement le storage
}));

export default EventDetailService;